import Head from 'next/head';
import Link from 'next/link';
import { useQuerySubscription } from 'react-datocms';
import { request } from 'lib/datocms';
import { menuFragment } from 'lib/fragments';

import { Layout, Container, PageIntro, Alert } from 'components';
import { ERROR_PAGE } from 'lib/constants';

export async function getStaticProps({ preview }) {
  const graphqlRequest = {
    query: `
      {
        menu {
          ...menuFragment
        }
      }
      ${menuFragment}
    `,
    preview,
  };

  return {
    props: {
      subscription: preview
        ? {
            ...graphqlRequest,
            initialData: await request(graphqlRequest),
            token: process.env.NEXT_DATOCMS_API_TOKEN,
            environment: process.env.NEXT_DATOCMS_ENVIRONMENT || null,
          }
        : {
            enabled: false,
            initialData: await request(graphqlRequest),
          },
    },
  };
}

export default function Error({ subscription }) {
  const {
    data: { site, menu },
  } = useQuerySubscription(subscription);

  return <>
    <Layout preview={subscription.preview} menu={menu}>
      <PageIntro title="404" introduction={ERROR_PAGE.TITLE_404} />

      <section className="PageBody">
        <Container>
          <Alert type="info" message={ERROR_PAGE.MESSAGE_404} />
          <Link href="/" className="Button Button--secondary">
            Return to Home
          </Link>
        </Container>
      </section>
    </Layout>
  </>;
}
